.footer-top ul {
    margin: 0;
    padding: 0;
    list-style: none;
}



/*---------footer---------*/
footer {
    background-color: #222023;
    background-image: -webkit-linear-gradient(top, #222023, #1e2c47);
    background-image: -moz-linear-gradient(top, #222023, #1e2c47);
    background-image: -o-linear-gradient(top, #222023, #1e2c47);
    background-image: linear-gradient(to bottom, #222023, #1e2c47);
    color: #fff;
    padding: 48px;
    font-size: 15px;
    margin-bottom: 15px;
}

footer h3 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
}

footer h4 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    display: inline-block;
    margin-bottom: 2px;
}

.about-footer li i {
    position: absolute;
    left: 0;
}

.about-footer li {
    padding-left: 35px;
    position: relative;
    margin-bottom: 40px;
}

.about-footer ul {
    margin-top: 40px;
}

footer a {
    color: #fff;
}

footer a:hover {
    color: #ed1b24;
}

.footer-title {
    border-bottom: 2px solid #a61f2d;
    padding-bottom: 25px;
    margin-bottom: 20px;
}

ul.footer-social {
    float: center;
}

ul.footer-social li {
    display: inline;
    margin-right: 12px;
}

ul .footer-social i {
    width: 30px;
    height: 30px;
    background: #fff;
    color: #222025;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    font-size: 16px;

    font-weight: 800;
}

ul.footer-social li:last-child {
    margin-right: 0px;
}

ul.footer-social i:hover {
    box-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px rgb(139, 0, 0);
    transform: scale(1.5);
    color: #fff;
}

.page-more-info li {
    margin-bottom: 14px;
}

footer .table td:first-child {
    font-weight: 600;
    padding-left: 33px;
}

footer .table td:last-child {
    text-align: right;
}

footer .table td {
    padding: 0px;
    border: 0;
}



footer .table td i {
    position: absolute;
    left: 0px;
    font-size: 21px;
    top: 6px;
}

footer .table td {
    position: relative;
    padding: 4px 0;
}

.footer-logo td {
    padding-right: 4px !important;
}

.footer-logo td:last-child {
    padding-right: 0px !important;
}

footer hr {
    border-color: #9294a0;
}

.footer-bottom p {
    text-align: right;
}

.footer-bottom {
    margin-top: 30px;
}

.open-hours hr {
    margin: 30px 0;
}