html,
body {
  /* font-family: "Amazon Ember" !important; */
  /* font-family: "Mier book" !important; */
  /* font-family: "Roboto,Arial,sans-serif" !important; */
  /* font-family: sans-serif; */
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* body {
  font-size: 1rem !important;
} */

/* Scroll Bar */

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(66, 66, 66, 0.2);
  border: 0px;
  background-clip: padding-box;
  border-radius: 5px;
}

nav {
  /* background-color: grey; */
  background-color: white;
  /* background-color: #232f3e; */
  /* padding: 1rem 1rem; */
  /* zoom: 90%; */
}

.navbar {
  /* border-bottom: 1px solid #d9dada; */
  /* position: sticky !important; */
  /* padding: 0.2rem 1rem !important; */
  padding: 0px 10px 0px 15px !important;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 2px 5px rgba(57, 63, 72, 0.3);
}


#search_btn {
  background-color: #e42326;
  color: white;

  background-position: right bottom;
}

#search_btn:hover {
  background-color: #bb0a12;
  color: white;
  background: linear-gradient(to top bottom, #e42326 50%, #bb0a12 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.3s ease-out;
  transform: rotate(360deg);
}

/* #search_btn:hover .fa-search{
  transition: all 0.3s ease-out;
  transform: rotate(360deg);
} */

.input-group {
  margin-right: 35%;
}

#search_field {
  height: 2.4rem;
  padding: 0.5rem 1rem;
  /* width: 300px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

#search_field:focus {
  outline: none;
  border-color: #e4232661;
  box-shadow: 0 0 0 0.2rem rgb(238 54 56 / 17%);
}

#login_btn,
#view_btn {
  /* background-color: #d3d3d3; */
  background-color: #e42326;
  color: white;
  padding: 0.3rem 1.5rem;
  font-family: calibri;
}

#login_btn {
  background-color: #bb0a12;
  color: white;
  padding: 0.3rem 1.5rem;
  font-family: calibri;
}

#view_btn:hover {
  /* background-color: #900820; */
  color: white;
  padding: 0.3rem 1.5rem;
  font-family: calibri;
  font-weight: 600;
  background: linear-gradient(to left, #e42326 50%, #bb0a12 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.5s ease-out;
}

#view_btn {
  /* background-color: #339d88; */
  /* background-color: #700921; */
  background-color: #e42326;
  color: white;
  font-size: 13px;
  font-weight: 500;
  background-position: right bottom;
}

#cart {
  font-size: 17px;
  color: #212529;
  /* color: white; */
}


#cart_count {
  /* background-color: #e42326; */
  padding: 0.2rem 0.5rem;
  border-radius: 25%;
  color: #212529;
  color: #545b62;
  /* /* color: white; */
  font-size: 1.5rem;
}


#cart_count1 {
  background-color: #e42326;
  padding: 0.1rem 0.4rem;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
}



.cartim {
  font-weight: normal;
}

/* Home page */
#products_heading {
  /* margin-top: 1.8rem; */
}

.serach-margin {
  margin-bottom: -17px;
}

.card1 {
  height: 420px;
}

.card1:hover {
  height: 420px;
  /* background: #e2e2e2; */
  /* background: #eaeaea; */
  /* background: #f1f3f4; */
  cursor: pointer;
}

.card9 {
  height: 100%;
}

.card8:hover {
  background: #808080d9;
}

.card9:hover {
  background: #28a746dc;
}

.card10:hover {
  background: #06a4ccdc;
}

.card11:hover {
  background: #4169e1d5;
}

.card12:hover {
  background: #fa9d23d8;
}

.card-title a {
  color: #2e2e2e;
  letter-spacing: 0;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  /* font-size: 14px; */
  font-size: 0.8rem;
  line-height: 20px;
  /* font-family: "Mier book"; */
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
}


.card-title a:hover {
  color: #cc3f06;
  text-decoration: none;
}

.card-body {
  padding-left: 0;
}

.cart-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card-text {
  font-size: 0.8rem;
  /* color: #388e3c; */
  color: #7f0710;

}

.cart-discount {
  /* background: #28a745 !important; */
  background: #388e3c !important;
  color: WHITE;
  border-radius: 10%;
  padding: 2px;
}


.card-img-top {
  width: 100% !important;
  height: 180px !important;
  display: inline-block;
  overflow: hidden;
  display: block;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.card-img-top:hover {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.ratings {
  font-size: 1.2rem;
  color: #e42326;
  /* display: none; */
}

#no_of_reviews {
  font-size: 0.85rem;
  color: grey;
  margin-left: 0.5rem;
}

/* Footer */
.footer1 {
  margin-top: -1rem;
  color: grey;
  bottom: 0;
  width: 100%;
}

/* Ratings */

.rating-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
  color: #e42326;
}

.rating-outer::before {
  content: "\f006 \f006 \f006 \f006 \f006";
}

.rating-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.rating-inner::before {
  content: "\f005 \f005 \f005 \f005 \f005";
  color: #e42326;
}

.dropdown-item:hover {
  color: #16181b !important;
  text-decoration: none !important;
  background-color: #eef0f3 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none !important;
  background-color: #d2042d !important;
}

/* Product Details Page */
#product_id {
  color: grey;
  font-size: 0.8rem;
}

#product_price {
  font-size: 1.7rem;
  font-weight: bold;
}

#cart_btn {
  border-radius: 1.5rem;
  background-color: #e42326;
  /* background-color: #800710; */
  border: none;
  width: 119px;
  padding: 0.6rem 1rem;
  background-position: right bottom;
}

#review_btn {
  border-radius: 1rem;
  background-color: #800710;
  border: none;
  padding: 0.5rem 1rem;
  background-position: right bottom;
}

#cart_btn:hover,
#review_btn:hover {
  background-color: #bb0a12;
  font-weight: 600;
  /* background: linear-gradient(to left, #e42326 50%, #bb0a12 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.5s ease-out; */
}

#product_seller {
  color: grey;
  font-size: 0.9rem;
}

#stock_status {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.3rem;
}

.redColor {
  color: red;
}

.greenColor {
  color: green;
}

#product_image {
  /* margin-top: 3rem; */
  margin-top: 3em;
  z-index: 100;
}

/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}

/* .loader:after {
  content: "";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #e42326; 
  border-color: #e42326 transparent;
  animation: lds-dual-ring 1.2s linear infinite; 
} */

/* .loader img{
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #e42326;
  border-color: #e42326 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
} */

/* @keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

/* //Spinner */
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: #e42326;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Cart */

.cart-item {
  margin: 1.8rem 0rem;
  padding: 0rem 0.7rem;
}

.cart-item a {
  color: rgb(40 32 32);
}

#delete_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.5rem;
  border: none;
}

#card_item_price {
  /* color: #e42326; */
  font-weight: bold;
  font-size: 1.4rem;
}

#checkout_btn,
.review-btn {
  background-color: #e42326 !important;
  border-color: #e42326 !important;
  /* margin-top: 2rem !important; */
  border-radius: 5rem !important;
  background-position: right bottom !important;
}

#checkout_btn:hover,
.review-btn:hover {
  background: linear-gradient(to left, #e42326 50%, #bb0a12 50%) !important;
  background-size: 200% 100% !important;
  background-position: left bottom !important;
  transition: all 0.5s ease-out !important;
}

#view_order_details {
  background-color: #e42326;
  border-color: #e42326;
}

#order_summary {
  border: 1px solid #e3e3e3;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
}

.order-summary-values {
  float: right;
  font-weight: bold;
}

.stockCounter input {
  border: none;
  width: 3rem;
  text-align: center;
  -moz-appearance: textfield;
}

.plus,
.minus {
  padding: 0.1rem 0.5rem;
}

.stockCounter input::-webkit-outer-spin-button,
.stockCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;

}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}

/* Login & Register */

.wrapper {
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  /* background-image: url("/images/login.jpg");
  background-size: 100% 100%; */
  /* backdrop-filter: brightness(50%); */
}

.wrapper form {
  padding: 2.5rem 3rem;
  width: 100%;
  border: 2px solid rgba(255, 255, 255, .5);
  border-radius: 20px;
  color: black;
  background-color: white;
  /* background-color: hsla(0, 0%, 10%, 0.1);
  backdrop-filter: blur(8px); */
}

.label-dark {
  color: #333;
  font-weight: bold;
}

.wrapper form .btn {
  background-color: #e42326;
  border-color: #d2042d;
  color: white;
  margin-top: 2.5rem;
}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;
}

.wrapper1 {
  /* display: flex; */
  align-items: center;
  margin-top: 5rem;
  font-weight: 700;
  margin: 11px;
}

.headcat {
  margin: 11px;
}

.wrapper1 form {
  padding: 1rem 2rem;
}

.wrapper1 form .btn {
  background-color: #e42326;
  border-color: pink;
  color: white;
  /* margin-top: 2.5rem; */
}

.wrapper1 form .btn:hover {
  background-color: hwb(348 3% 5%);
  border-color: #d2042d;
  color: white;
  margin-top: 2.5rem;
}

.wrapper1 form a {
  font-size: 0.9rem;
  color: grey;
}

/* Avatar */
.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

/* Header User Profile */

.avatar-nav {
  margin-right: 0.6rem;
  height: 2.2rem;
  width: 2.2rem;
}

/* Checkout Steps */

.checkout-progress div {
  box-sizing: border-box;
}

.checkout-progress {
  display: block;
  clear: both;
  margin: 20px auto;
  width: auto;
  font-family: sans-serif;
  overflow: auto;
}

.step {
  font-size: 12px;
  margin: 0;
  border: 0;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 5px 10px 5px 15px;
  color: grey;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
}

.incomplete {
  background: #eeeeee;
}

.active-step {
  background: #e42326;
  color: #fff;
}

.triangle-active {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #e42326;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-active {
  width: 0;
  float: left;
  border-top: 20px solid #e42326;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #e42326;
  margin-right: -1px;
}

.triangle-incomplete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #eeeeee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-incomplete {
  width: 0;
  float: left;
  border-top: 20px solid #eeeeee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eeeeee;
  margin-right: -1px;
}

/* User Profile */

.avatar-profile {
  height: 16rem;
  width: 16rem;
}

.user-info h4 {
  font-weight: 800;
  color: grey;
  margin-top: 2rem;
}

.user-info p {
  font-size: 1.1rem;
}

#edit_profile {
  background-color: #e42326;
  border-color: #e42326;
}

/* Confirm Order */

.order-confirm p,
.order-details p {
  margin-left: 1.5rem;
}

.update-btn {
  background-color: #e42326;
  border-color: #e42326;
  color: white;
}

.update-btn:hover {
  color: white;
}

/* Pagination */
.page-item.active .page-link {
  background-color: #e42326 !important;
  border-color: #e42326 !important;
  color: white !important;
}

.page-link {
  color: #e42326 !important;
}

.page-link:hover {
  color: #e42326 !important;
}

.indicent {
  color: #DC143C;
}

/* Admin Sidebar */

.sidebar-wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 100vh;
  font-weight: normal;
  margin-top: 0;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

#sidebar {
  min-width: 100%;
  max-width: 100%;
  background: #232f3e;
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #232f3e;
}

#sidebar ul.components {
  padding: 5px 0px;
  border-bottom: 1px solid #232f3e;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: white;
  text-decoration: none;
}

#sidebar ul li a:hover {
  color: #232f3e;
  background: #fff;
}

#sidebar ul li a i {
  margin-right: 0.3rem;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  /* color: #fff;
  background: #232f3e; */
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 1rem !important;
  padding-left: 30px !important;
  background: #232f3e;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* Products List Admin */
#products_table .btn {
  padding: 0.2rem 0.5rem;
  margin-left: 0.4rem;
}

/* Dashboard */

.card-font-size {
  font-size: 1.5rem;
}

/* Reviews */

.rating {
  margin-top: 10rem;
}

.stars {
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.star {
  display: inline;
  list-style: none;
  font-size: 3rem;
  padding-left: 0.9rem;
  color: #e3e3e3;
}

.star:first-child {
  padding-left: 0;
}

.orange {
  color: #e42326d5;
}

.yellow {
  color: #d2042d;
}

.review_user {
  font-size: 0.8rem;
  color: grey;
}

.plus-btn {
  background-color: #ffa500 !important;
  color: white !important;
}

.minus-btn {
  background-color: #009fe2 !important;
  color: white !important;
}

.bg-amount {
  /* background-color: #008080!important; */
  background-color: #2f79ab !important;
  /* background-color: #485769 !important; */
}

.bg-products {
  background-color: #28a745 !important;

}

.bg-orders {
  background-color: #06a4cc !important;
}

.bg-users {
  background-color: #4169e1 !important;
}

.bg-stock {
  background-color: #fa9c23 !important;
}

.bg-footer {
  background-color: #232f3e !important;
  align-self: flex-end;
}

/* .bg-footerMenu {
  background-color: rgba(35, 47, 62, .8509803921568627) !important;
  margin-top: 8rem;
} */

.fz {
  font-size: 12px;
  color: darkgray !important;
}

.copy {
  color: #dbdbe9;
}

a:hover {
  color: white;
  text-decoration: underline;
}

.copyright {
  color: grey;
  color: #dbdbe9;
}

.card-related {
  text-align: center;
  opacity: 1;
  width: 200px;
  color: white;
  background-color: #f7f7f7;
  margin-left: 0;
}

.card-title-related {
  color: #262626;
  display: block;
  overflow: hidden;
  font-size: 12px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0 !important;
}

.img1 {
  display: block;
  margin: auto;
}

.slick-prev:before {
  font-size: 40px !important;
  margin-left: -20px !important;

  display: -webkit-flex;
  display: -ms-flexbox;
  font-family: 'none' !important;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  position: absolute;
  top: calc(50% - 52px);
  width: 47px;
  height: 104px;
  background-color: hsla(0, 0%, 100%, .98);
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 11%);
  transition: opacity .1s ease-in;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-radius: 4px 0 0 4px;
  content: ' \276E' !important;
  color: black !important;
}

.slick-next:before {
  font-size: 40px !important;
  margin-left: -20px !important;

  display: -webkit-flex;
  display: -ms-flexbox;
  font-family: 'none' !important;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  position: absolute;
  top: calc(50% - 52px);
  width: 47px;
  height: 104px;
  background-color: hsla(0, 0%, 100%, .98);
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 11%);
  transition: opacity .1s ease-in;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-radius: 4px 0 0 4px;
  content: ' \276F' !important;
  color: black !important;
}

.slick-next:before:hover,
.slick-prev:before:hover {
  box-shadow: 1px 2px 10px 1px rgb(0 0 0 / 30%);
}

.follow {
  background-color: #3897f0 !important;
  border: 1px solid #3897f0 !important;
  border-radius: 4px;
  color: #fff;
  margin-top: 20px !important;
}

.readonly {
  background-color: hsl(0deg 0% 93%);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
}

.zoom {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  z-index: 100;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  /* width: 250% !important; */
}

.verticalSlider {
  width: 100%;
  max-width: 300px;
  max-width: 800px;
}

.carousel-control-next-icon {
  position: none !important;
  top: calc(50% - 52px);
  /* width: 25px!important;
  height: 50px!important; */
  transition: opacity .1s ease-in;
  /* background-color: rgba(0, 0, 0, 0.3) !important;
  background-size: 100%, 100% !important;
  border-radius: 50%;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.158); */
}

.carousel-control-prev-icon {
  position: none !important;
  top: calc(50% - 52px);
  /* width: 25px!important;
  height: 50px!important; */
  transition: opacity .1s ease-in;
  /* background-color: rgba(0, 0, 0, 0.3) !important;
  background-size: 100%, 100% !important;
  border-radius: 50%;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.158); */
}

.visually-hidden {
  display: none;
}

.slick-next {
  right: 7px !important;
}

.slick-prev {
  left: 20px !important;
  z-index: 1;
}

.ht {
  height: 500px;
  overflow: hidden;
}

/* gallery {
  position: relative;
  width: 100vw;
  max-width: 960px;
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto;
  padding: 20px;
} */

.gallery-img {
  width: 100%;
  border: 10px solid #fff;
  margin: 5px auto;

  @media all and (min-width: 768px) {
    width: calc(50% - 10px);
  }
}


.social {
  position: absolute;
  /* top:50%; */
  right: 0;
  /* width: 120px; */
  transform: translateY(-10%);
}

.link-des {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  color: #8e90a7;
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}


.bg-user {
  background-color: rgb(227, 242, 253);
}

.dataTables_scrollHeadInner {
  margin-bottom: -18px;
}

/* 
table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  right: 16px;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd";
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: .3;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  position: relative;
  cursor: pointer;
}

table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  right: 16px;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd";
}



table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting {
    padding-right: 30px;
}
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
    position: relative;
    cursor: pointer;
}

table.table thead th {
    border-top: none;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
table.table-sm th, table.table-sm td {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}
table.table th, table.table td {
    padding-top: 1.1rem;
    padding-bottom: 1rem;
} */

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
  padding-right: 30px;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  position: relative;
  cursor: pointer;
}



.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border: 1px dashed #dee2e6 !important;
}

table.table-sm th,
table.table-sm td {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

table.table th,
table.table td {
  padding-top: 1.1rem;
  padding-bottom: 1rem;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border: 1px dashed #dee2e6 !important;
}

.table-bordered td,
.table-bordered th {
  border: 1px dashed #dee2e6 !important;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px dashed #dee2e6 !important;
}

.table-bordered td,
.table-bordered th {
  border: 1px dashed #dee2e6 !important;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px dashed #dee2e6 !important;
}

table th {
  font-size: 0.9rem;
  font-weight: 400;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\21C5";
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 16px;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\21C5";
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

.serach-result {
  line-height: 20px !important;
  font-style: normal !important;
  text-transform: none !important;
  text-decoration: none !important;
  font-weight: 500;
  font-size: 16px;
  margin-top: 8px;
  display: inline-block;
  margin-left: 12px;
}

.slider-bg {
  /* background-color: #eaeded; */
  background-color: #eaedef;
  /* background-color: #232f3ed9; */
}

.slider-nav-bg {
  /* background-color: #eaedef; */
  /* background-color: #232f3ed9; */
  /* background-color: #485769; */
  /* background-color: rgb(39 154 133); */
}

.pd-scroll {
  overflow-y: scroll;
  width: 100%;
  height: 600px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.pd-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pd-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.custom-img {
  height: 120px !important;
}

.related-slider {
  margin-bottom: -16px;
}

.related-heading {
  margin-left: 13px;
}

.menu-button {
  color: white !important;
}

.ullist {
  list-style-type: none;
}

.user-bg {
  background-color: #f0f5f9;
}

.m-address {
  margin: 6rem !important;
  margin-top: -1rem !important;
}

.abc {
  margin: 12rem !important;
  margin-top: 0rem !important;
}

.address-btn:hover {
  background: linear-gradient(to left, #e42326 50%, #bb0a12 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.5s ease-out;
}

.address-btn {
  background-color: #e42326;
  border-color: #e42326;
  background-position: right bottom;
}

.cart-item a:hover {
  color: grey;
  text-decoration: none;
}

.proda {
  color: #212529;
  text-decoration: none;
  background-color: transparent;
}

.proda:hover {
  color: #050b11;
  background-color: transparent;
}


.banner {
  /* width: 100%; */
  /* height: 100%; */
  /* height: 420px; */
  max-width: 100%;
  height: 360;
  background-size: contain;
}

.banner-image {
  width: 100%;
  height: 400px;
}

.filter-sort {
  margin-left: 310px;
}

.cart-sup {
  top: -1.2em;
}

.sort-btn:active {
  padding-bottom: 10px !important;
  border-bottom: 3px solid #dc1410 !important;
}

.sort-btnactive {
  padding-bottom: 10px !important;
  border-bottom: 3px solid #dc1410 !important;
}



/* Button style */
.sort-btn {
  border: none;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.sort-btn.active {
  background-color: #bb0a12;
}


.text-right .btn {
  line-height: 0;
  font-size: 0.8rem;
  margin-right: 41px;
}

.filter-apply {
  margin-top: -38px;
}

.f1 {
  border-right: 1px solid #eee;
}

.dropdown-list-bg {
  background-color: #434e5a;
}

.dropdown-cl {
  color: white;
}

.scroll {
  margin: 4px, 4px;
  padding: 4px;
  height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: initial;
}

/* vertical mega menu navbar */
.menu-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

a.menu-head {
  border: none !important;
}

.menu {
  width: 100%;
  font-weight: 500;
  /* font-size: 15px; */
  /* line-height: 15px; */
  position: relative;
  margin: 0;
  background-color: white;
}

/* .menu {
  width: 100%;
  font-family: "Amazon Ember";
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  position: relative;
  padding: 20px 10px;
  margin: 0;
  background-color: white;

} */

.menu a,
.menu a:link,
.menu a:visited,
.menu a:focus,
.span1 {
  color: #212529;
  text-decoration: none;
  border: 1px solid #eee;
  padding: 15px;
}

.menu a:hover {
  color: #e42326;
  text-decoration: none;
}

.menu>li {
  display: block;
  margin-left: -40px;
  /* border-left: 1px solid rgba(255, 255, 255, 0.11); */
  /* border: 1px solid #eee; */
  color: #212529;
}

.menu>li>a {
  padding: 16px 18px;
  font-size: 14px;
  display: block;
  color: #232f3e;
  text-decoration: none;
}

.menu>li:hover>a {
  color: #e42326;
  box-shadow: 1px 0 0 2px rgb(0 0 0 / 16%);
  box-sizing: border-box;
}

.menu>li:hover {
  background-color: #fff;
}

/* Megadrop width dropdown */
.menu>li>.megadrop {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  list-style: none;
  top: -19px;
  left: 309px;
  width: 800px;
  min-height: 100%;
  text-align: left;
  margin-top: 30px;
  padding: 0;
  z-index: 99999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  background-color: #fffff1;
  color: #212529;
}

.menu>li:hover .megadrop {
  opacity: 1;
  visibility: visible;
  margin-top: 18px;
  margin-left: 4px;
  margin-bottom: 25px;
}

.menu ul li:hover:after {
  color: #e42326;
}

.menu .col {
  width: 24%;
  float: left;
  color: white;
  margin: 0 0 0 2.2%;
}

.menu .col ul {
  padding: 0;
  margin: 0;
}

.menu .col ul li {
  padding: 0;
  list-style: none;
  font-size: 11px;
}

.menu .col h3 {
  font-size: 16px;
  padding: 10px 0;
  margin: 5px 0 5px 0;
  color: #212529;
  background: #f5f4f2;
}

.menu .col ul li a {
  display: block;
  padding: 10px;
  color: #212529;
  text-align: center;
  font-size: 13px !important;
  padding-left: 0px !important;
  background: #f5f4f2;
}

.menu .col ul li a:hover {
  /* color: #111; */
  color: #e42326;
  text-decoration: underline;
}

.menu>li>ul li ul,
.menu li>ul li,
.menu>li>.megadrop,
.menu>li>ul,
.menu>li {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-outs;
}

/* banner images setting */
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: none !important;
  transform: none !important;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: none !important;
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: none !important;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: none !important;
  transition: none !important;
  transition: none !important;
}

.sp {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  /* color: #e42326 !important; */
  box-shadow: 1px 0 0 2px rgb(0 0 0 / 16%) !important;
  box-sizing: border-box !important;
  text-decoration: dashed !important;
  border: 1px solid #eee !important;
  outline: none !important;
  margin: 30px;
}

.sp-btn-add {
  margin-top: 0rem !important;
}

.hei {
  height: 70px;
}

.wid {
  min-width: 220px;
}

.wid:hover {
  text-decoration: none;
}

.ver-banner {
  /* margin-left: -11px; */
  padding-right: 0px;
}

.prod-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 1px;
  /* font-family: "Mier book"; */
  /* font-family: 'Helvetica Neue', sans-serif; */
  /* Example font family */
  color: #333;
  /* Example color */
  /* Adjust padding as needed */
}

.product-title {
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: "Mier book"; */
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.similar-products-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.fw-price {
  font-weight: 600;
}


/* Responsive Design */
@media (max-width: 767px) {
  .prod-heading {
    font-size: 20px;
    /* Adjust font size for smaller screens */
  }
}


/* For hiding table in product details */
.r1,
.r2,
.r3,
.r4,
.r5,
.r6 {
  display: "block";
}

.r7,
.r8,
.r9,
.r10,
.r11,
.r12,
.r12,
.r14,
.r15,
.r16,
.r17,
.r18,
.r19,
.r20,
.r21,
.r22,
.r23,
.r24,
.r25,
.r26,
.r27,
.r28,
.r29,
.r30,
.r32,
.r33,
.r34,
.r35,
.r36,
.r37,
.r38,
.r39,
.r40,
.r41,
.r42,
.r43,
.r44,
.r45,
.r46,
.r47,
.r48,
.r49,
.r50,
.r51,
.r52 {
  display: none !important;
}

.btn-cancel {
  color: #3e3030;
  background-color: #f2f2f2;
  border-color: #262122;
}

.menuorder {
  background: #269a85;
  color: white;
  border-color: initial;
  text-decoration: none !important;
  padding: 8px;
  outline: none !important;
}

.menuorder:hover {
  background: #269a85f1;
  color: white;
  border-color: initial;
  text-decoration: none !important;
  padding: 8px;
  outline: none !important;
}

.menuorder1 {
  color: #3e3030;
  background-color: white;
  border-color: #262122;
  text-decoration: none !important;
}

.menuorder1:hover {
  color: #3e3030;
  background-color: white;
  border-color: #262122;
  text-decoration: none !important;
}

/* .menuorder{
  background: #269a85;
  color: white;
  border-color: initial;
}

.menuorder1{
  color: #3e3030;
  background-color: white;
  border-color: #262122;
} */

/* .mybg{
  background-color: #f1f3f6;
} */

.marginleft {
  float: right;
}

/* zoom imgae */

.containerZoom {
  max-width: 1200px;
  margin: 0 auto;
  /* display: grid; */
  grid-template-columns: repeat(2, 1fr);
}

.leftZoom {
  display: flex;
  height: 10vh;
  /* position: fixed; */
}

.left_1Zoom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.img_wrapZoom {
  width: 80px;
  height: 80px;
  border: 1px solid #eee;
  z-index: 9999;
  cursor: pointer;
}

.img_wrapZoom img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.left_2Zoom img {
  width: 500px;
  height: 100%;
  object-fit: contain;
}

.activeimg {
  border: 2px solid #e77600;
}

.sale_price {
  /* color: #008a00; */
  color: #269a85;
  /* color: darkred */
}

.text-indianred {
  color: indianred;
}

.policy h1 {
  font-size: 28px;
}

.policy h2 {
  font-size: 22px;
}

.policy h3 {
  font-size: 18px;
}

h5.mn {
  margin: 12px;
}

/* 404 */
.content1 {
  height: 100%;
  min-height: 450px;
  font-family: "Dosis", sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: #5d7399;
  height: 100%;
  position: relative;
  z-index: 1;
  background-color: #d2e1ec;
  background-image: linear-gradient(to bottom, #bbcfe1 0%, #e8f2f6 80%);
  overflow: hidden;
}

.snow {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 20;
}

.main-text {
  padding: 20vh 20px 0 20px;
  text-align: center;
  line-height: 2em;
  font-size: 5vh;
}

.home-link {
  font-size: 0.6em;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
  border-bottom: 1px dashed rgba(93, 115, 153, 0.5);
}

.home-link:hover {
  opacity: 1;
}

.ground {
  height: 160px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f6f9fa;
  box-shadow: 0 0 10px 10px #f6f9fa;
}

.ground:before,
.ground:after {
  content: "";
  display: block;
  width: 250px;
  height: 250px;
  position: absolute;
  top: -62.5px;
  z-index: -1;
  background: transparent;
  transform: scaleX(0.2) rotate(45deg);
}

.ground:after {
  left: 50%;
  margin-left: -166.6666666667px;
  box-shadow: -285px 315px 15px #b0bccf, -605px 595px 15px #b4bed1,
    -915px 885px 15px #bac4d5, -1180px 1220px 15px #7e90b0,
    -1505px 1495px 15px #bac4d5, -1820px 1780px 15px #9aa9c2,
    -2065px 2135px 15px #94a3be, -2355px 2445px 15px #97a6c0,
    -2685px 2715px 15px #a1aec6, -2990px 3010px 15px #8496b4,
    -3335px 3265px 15px #bac4d5, -3550px 3650px 15px #b0bccf,
    -3885px 3915px 15px #8a9bb8, -4185px 4215px 15px #b4bed1,
    -4535px 4465px 15px #8a9bb8, -4830px 4770px 15px #7e90b0;
}

.ground:before {
  right: 50%;
  margin-right: -166.6666666667px;
  box-shadow: 290px -310px 15px #a1aec6, 630px -570px 15px #9aa9c2,
    915px -885px 15px #8a9bb8, 1235px -1165px 15px #aab6cb,
    1460px -1540px 15px #a1aec6, 1835px -1765px 15px #9dabc4,
    2115px -2085px 15px #7e90b0, 2405px -2395px 15px #a1aec6,
    2745px -2655px 15px #a4b1c8, 3010px -2990px 15px #b4bed1,
    3260px -3340px 15px #b7c1d3, 3580px -3620px 15px #a7b4c9,
    3910px -3890px 15px #91a1bc, 4190px -4210px 15px #b0bccf,
    4520px -4480px 15px #9dabc4, 4780px -4820px 15px #a7b4c9;
}

.mound {
  margin-top: -80px;
  font-weight: 800;
  font-size: 180px;
  text-align: center;
  color: #dd4040;
  pointer-events: none;
}

.mound:before {
  content: "";
  display: block;
  width: 600px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 50px;
  z-index: 1;
  border-radius: 100%;
  background-color: #e8f2f6;
  background-image: linear-gradient(to bottom, #dee8f1, #f6f9fa 60px);
}

.mound:after {
  content: "";
  display: block;
  width: 28px;
  height: 6px;
  position: absolute;
  left: 50%;
  margin-left: -150px;
  top: 68px;
  z-index: 2;
  background: #dd4040;
  border-radius: 100%;
  transform: rotate(-15deg);
  box-shadow: -56px 12px 0 1px #dd4040, -126px 6px 0 2px #dd4040,
    -196px 24px 0 3px #dd4040;
}

.mound_text {
  transform: rotate(6deg);
}

.mound_spade {
  display: block;
  width: 35px;
  height: 30px;
  position: absolute;
  right: 50%;
  top: 42%;
  margin-right: -250px;
  z-index: 0;
  transform: rotate(35deg);
  background: #dd4040;
}

.mound_spade:before,
.mound_spade:after {
  content: "";
  display: block;
  position: absolute;
}

.mound_spade:before {
  width: 40%;
  height: 30px;
  bottom: 98%;
  left: 50%;
  margin-left: -20%;
  background: #dd4040;
}

.mound_spade:after {
  width: 100%;
  height: 30px;
  top: -55px;
  left: 0%;
  box-sizing: border-box;
  border: 10px solid #dd4040;
  border-radius: 4px 4px 20px 20px;
}

.btn1 {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  /* font-size: 1em; */
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
}

.btn1.green {
  border: 4px solid #e42326;
}

.btn1.green:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: #e42326;
  z-index: -1;
  transition: 0.2s ease;
}

.btn1.green:hover {
  color: var(--white);
  background: #d1e0eb;
  transition: 0.2s ease;
}

.btn1.green:hover:before {
  width: 100%;
}

.sizeProduct {
  width: 60% !important;
  height: 50px;
}

.upperCase1 {
  text-transform: uppercase;
}

.upperCase {
  text-transform: capitalize;
}

ul#menu li {
  display: inline;
  float: left;
  border: 1px solid #333;
  border-radius: 2px;
  margin-top: 10px;
  font-family: Roboto, sans-serif !important;
  padding: 0;
  list-style: none;
  margin-bottom: 5px;
  float: left;
  margin: 0 10px 10px 0;
  display: block;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
}

ul#menu li a:hover {
  color: #e42326;
  text-decoration: none;
}

.selected a {
  color: #323232;
  text-decoration: none;
}

ul#menu li a:active {
  color: white;
  background: #333;
  text-decoration: none;
}

ul#menu li:active {
  color: white;
  background: #333;
  text-decoration: none;
}

.list-active {
  color: white;
  background: #333;
  text-decoration: none;
}


.cart-bg {
  /* background-color: #f5f4f2 !important; */
  border-radius: 25px;
  border: none !important;
}

.item-cart-bg {
  background-color: #f5f4f2 !important;
  border-radius: 25px;
}

.logo1-image {
  width: 100%;
  height: auto;
  /* This maintains the aspect ratio of the image */
  max-width: 100%;
  /* Ensures the image does not exceed its original size */
}


/* menu-list */
.sidebar-wrapper1 {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 50vh;
  font-weight: normal;
  margin-top: 0;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

#sidebar1 {
  min-width: 100%;
  max-width: 100%;
  color: #fff;
  transition: all 0.3s;
}

#sidebar1.active {
  margin-left: -250px;
}

#sidebar1 .sidebar-header1 {
  padding: 20px;
  background: #232f3e;
}


#sidebar1 ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: #232f3e;
  text-decoration: none;
}

#sidebar1 ul li a:hover {
  color: #e42326;
  border-bottom: 2px solid #f2f2f2 !important;
  border-top: 2px solid #f2f2f2 !important;
}

#sidebar1 ul li a i {
  margin-right: 0.3rem;
}

#sidebar1 ul li.active>a,
a[aria-expanded="true"] {
  /* color: #fff;
  background: #232f3e; */
}

.contact {
  font-size: 80%;
  position: relative;
  color: #485769;
  border: none;
}

.contact1 {
  background-color: #eaedef;
  /* background-color: #f78b27 */
}


@media (max-width: 767px) {
  .filter-sort {
    margin-left: 0px;
  }

  .menu>li>.megadrop {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    list-style: none;
    /* top: 9px; */
    left: 134px;
    width: 800px;
    min-height: 100%;
    text-align: left;
    /* margin-top: 30px; */
    padding: 0;
    z-index: 99999;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    border-top: 1px solid #e42326;
    border-right: 1px solid #e42326;
    border-bottom: 1px solid #e42326;
    background-color: #f1f1f1;
    border: 1px solid #eee;
    color: #212529;
  }

}


@media (max-width: 767px) {
  .mobile-cart {
    margin-left: 68px;
    margin-top: -12.5rem !important;
  }

  .cart-bg {
    margin: 0px 7px;
  }

  .left_2Zoom img {
    width: 200px !important;
    height: auto !important;
    object-fit: contain;
  }

  .leftZoom {
    display: flex;
    height: 30vh;
  }

  .navbar {
    /* zoom: 60% !important; */
    position: relative !important;
    /* height: 127px; */
  }

  .contact {
    margin-left: 50px !important;
    font-size: 53% !important;
    position: relative;
  }

  .css-e53awj-MuiStack-root {
    display: block !important;
    line-height: 3 !important;
    margin: -31px;
    padding: 20px;

  }


}

.scrollTop {
  float: right;
  border-radius: 8px;
  border: none;
  line-height: 35px;
  margin-right: 15px;
  border: 0;
  padding: 4px;
  font-size: 28px;
  outline: none !important
}

.hr-productDetails {
  border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.bg-enquiry {
  background-color: rgb(0 123 255 / 12%) !important;
  margin-top: -76px
}

.btn-bulk {
  border-color: rgb(0, 0, 0, 0.4) !important;
}

.cart-item p {
  white-space: nowrap;
}

.rating-size {
  font-size: 22px
}

.order-btn {
  border-radius: 1rem;
  background-position: right bottom;
}

.order-btn:hover {
  background: linear-gradient(to left, #269a85 50%, #269a85a6 50%) !important;
  /* background: linear-gradient(to left, #269a85 50%, #065e4e bb0a12 50%) !important; */
  background-size: 200% 100% !important;
  background-position: left bottom !important;
  transition: all 0.5s ease-out !important;
  color: white;
}

/* .left_2Zoom img {
  border: 1px solid rgb(214, 214, 214) !important;
} */

.banner-text {
  font-size: 0.9rem;
  color: grey;
  text-decoration: none;
  background-color: transparent;
  color: red;
}

.bannerRemove {
  z-index: 0 !important;
}

.css-e53awj-MuiStack-root button:focus {
  outline: 5px white !important;
  height: 34px;
}

.css-e53awj-MuiStack-root button {
  outline: 5px white !important;
  height: 34px;
}

.canvasjs-chart-credit {
  display: none !important
}

.viewpass {
  float: right;
  margin-left: -25px;
  margin-top: -26px;
  position: relative;
  font-size: large;
  z-index: 2;
}

.testimonials__quote__text {
  font-size: 16px;
  /* Adjust font size as needed */
  line-height: 1.6;
  /* Adjust line height for better readability */
  color: #333;
  /* Example color */
  padding: 10px 0;
  /* Add padding for better spacing */
  margin-bottom: 20px;
  /* Add margin for separation from other elements */
  text-align: justify;
}

.glow-on-hover {
  border-radius: 2rem !important;
  width: 164px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #e42326;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  border-radius: 2rem !important;
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #bb0a12
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  border-radius: 2rem !important;
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #e42326;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}


@media (max-width: 576px),
(max-width: 767px) {
  .navbar-brand img {
    width: 120px;
    height: auto;
  }
}



/* Custom CSS for responsive and increased size header */
@media (max-width: 768px) {
  .navbar-brand img {
    width: 100%;
    height: auto;
    max-width: 150px;
    /* Increase the size of the logo */
    max-height: 150px;
  }

  .fa-3x {
    font-size: 3rem;
    /* Increase icon size */
  }

  .cartim {
    display: none;
  }

  .navbar .dropdown-menu {
    width: 100%;
    text-align: center;
  }

  .pd-scroll {
    overflow-y: hidden;
    height: auto;
    overflow-x: hidden;
  }

}

.navbar-brand {
  max-height: 180px;
  /* Increase maximum height of the navbar */
}

@media (max-width: 767.98px) {
  .mmb-mobile {
    margin-bottom: 0.6rem;
    margin-top: 1rem;
  }
}






/* Styles for desktop and mobile menu */
/* Styles for desktop and mobile menu */
.nav.sidebar1 .menu li {
  position: relative;
  padding: 5px;
  /* Adjust padding for desktop */
}

/* .nav.sidebar1 .menu li .megadrop {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 999;
  width: 100%;
} */

.nav.sidebar1 .menu li:hover .megadrop {
  display: block;
}

/* Media query for mobile */
@media (max-width: 768px) {
  .nav.sidebar1 .menu li {
    position: static;
    padding: 0;
    /* Remove padding for mobile */
  }

  .nav.sidebar1 .menu li .megadrop {
    position: static;
    display: none;
  }

  .nav.sidebar1 .menu li .megadrop .col {
    display: block;
  }
}

/* Media query for desktop */
@media (min-width: 769px) {
  /* .nav.sidebar1 .menu li .megadrop {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 999;
    width: auto;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  } */

  .nav.sidebar1 .menu li:hover .megadrop {
    display: block;
    /* Show dropdown on hover for desktop */
  }

  .nav.sidebar1 .menu li .megadrop .col {
    display: inline-block;
    /* Adjust layout for desktop dropdown */
    vertical-align: top;
    margin-right: 20px;
    /* Adjust margin between columns */
  }

  .nav.sidebar1 .menu li .megadrop .col ul li {
    margin-bottom: 10px;
    /* Adjust spacing between submenu items */
  }

  .nav.sidebar1 .menu li .megadrop .col ul li a {
    color: #333;
    /* Text color for submenu links on desktop */
    text-decoration: none;
    /* Remove underline */
    display: block;
  }

  .nav.sidebar1 .menu li .megadrop .col ul li a:hover {
    color: #007bff;
    /* Text color for submenu links on hover */
  }
}




/* shar social medai icon */
.share-button-container {
  position: relative;
  display: inline-block;
  float: right;
}

.share-icon {
  background: #007bff;
  border: none;
  color: white;
  padding: 7px;
  border-radius: 20%;
  cursor: pointer;
  font-size: 16px;
}

.social-icons {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 60px;
  top: 0;
  z-index: 1;
}

.social-icons a {
  margin: 0 5px;
  color: white;
  padding: 7px;
  border-radius: 20%;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  transition: background 0.3s;
}

.social-icons a:hover {
  background-color: #ddd;
}

.social-icons a svg {
  width: 20px;
  height: 20px;
}

.social-icons .facebook {
  background-color: #3b5998;
}

.social-icons .facebook:hover {
  background-color: #2d4373;
  color: #fff;
}

.social-icons .twitter {
  background-color: #1da1f2;
}

.social-icons .twitter:hover {
  background-color: #0b7bc3;
}

.social-icons .linkedin {
  background-color: #0077b5;
  color: #fff;
}

.social-icons .linkedin:hover {
  background-color: #005a8c;
}

.social-icons .whatsapp {
  background-color: #25D366;
  color: #fff;
}

.social-icons .whatsapp:hover {
  background-color: #128C7E;
}

.share-button-container:hover .social-icons {
  display: flex;
  animation: slideIn 0.5s forwards;
}

@media only screen and (max-width: 600px) {
  .social-icons {
    flex-direction: column;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 5px);
    width: max-content;
  }

  .social-icons a {
    margin: 5px 0;
    display: block;
    padding: 10px;
    text-align: center;
  }
}



@import url("../node_modules/font-awesome/css/font-awesome.min.css");